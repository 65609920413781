var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Autocomplete',_vm._b({on:{"change":_vm.updateValue,"searchinput":_vm.seekInput}},'Autocomplete',{
    ..._vm.$props,
    options: _vm.options,
    value: _vm.newValue,
    loading: _vm.loading,
    noFilter: true,
    returnObject: true,
    hideNoData: false,
    clearable: true,
    itemText: 'SeekDisplay',
    itemValue: 'Id'
  },false))
}
var staticRenderFns = []

export { render, staticRenderFns }