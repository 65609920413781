<template>
  <Autocomplete 
    v-bind="{
      ...$props,
      options,
      value: newValue,
      loading,
      noFilter: true,
      returnObject: true,
      hideNoData: false,
      clearable: true,
      itemText: 'SeekDisplay',
      itemValue: 'Id'
    }"
    @change="updateValue"
    @searchinput="seekInput"
  >
  </Autocomplete>
  
</template>

<script>
import Autocomplete from '@/components/form/Autocomplete.vue'
import { AgencyService } from '@/api/AgencyService'
import { sanitizeSeek } from '@/scripts/helper'

export default {
  name: "AgencyAutocomplete",
  components: {
    Autocomplete
  },
  data() {
    return {
      newValue: null,
      options: [],
      loading: false,
      timeout: null,
    }
  },
  props: {
    value: Object,
    rules: String,
    label: String,
    clearable: Boolean,
    ignoreCache: Boolean,
    required: Boolean,
    errors: Array,
    disabled: Boolean,
    name: String,
    module: String
  },
  emits: [
      'change'
  ],
  methods: {
      updateValue(v) {

        if (v) {
          this.newValue = v;
        }
        else {
          this.newValue = null;
        }

        this.emitValue();
      },
      emitValue() {
          this.$emit('change', this.newValue)       
      },
      seekInput(q) {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.seek(q);
        }, 300)
      },
      seek(q) {
        q = sanitizeSeek(q);

        if (!this.ignoreCache && this.newValue && this.newValue.SeekDisplay.indexOf(q) > -1) return;

        if (q && q.length > 2) {
            this.loading = true;

            const noCacheHeader = {
                headers: { 'cache-control': 'private,no-cache, no-store, max-age=0' }
            }
          
            AgencyService.seek({ q: q }, this.ignoreCache ? noCacheHeader : {})
              .then((data) => {
              this.options = data.map(o => { return o });
            }).catch(() => {
            
            }).finally(() => {
                this.loading = false;
            });
            
          }
      }
  },
  watch: {
      value: {
          immediate: true,
          deep: true,
          handler(newVal) {
              if (this.newValue != newVal) {
                  this.newValue = newVal;
                  const objIndex = this.options.findIndex((o => o.id == newVal.id));
                  if (objIndex > -1) {
                      this.options[objIndex] = this.newValue;
                  } else {
                      this.options.push(this.newValue);
                  }
              }
          }
      }
  },
}
</script>
